
// import React, { useState, useEffect } from "react";
// import Header from "../Header/Header";
// import { Form, Row, Col, Card, Table, Button, Dropdown, DropdownButton } from 'react-bootstrap';
// import Container from "react-bootstrap/Container";
// import logo from '../../Assets/logo_darkblue.png';
// import axios from "axios";
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Brush, AreaChart, Area, ResponsiveContainer, } from "recharts";
// const Analytics = ({ }) => {
//   const data = [
//     {
//       name: "Page A",
//       uv: 4000,
//       pv: 40,
//       amt: 2400,
//     },
//     {
//       name: "Page B",
//       uv: 3000,
//       pv: 898,
//       amt: 2210,
//     },
//     {
//       name: "Page C",
//       uv: 2000,
//       pv: 900,
//       amt: 2290,
//     },
//     {
//       name: "Page D",
//       uv: 2780,
//       pv: 1208,
//       amt: 2000,
//     },
//     {
//       name: "Page E",
//       uv: 1890,
//       pv: 1800,
//       amt: 2181,
//     },
//     {
//       name: "Page F",
//       uv: 2390,
//       pv: 2200,
//       amt: 2500,
//     },
//     {
//       name: "Page G",
//       uv: 3490,
//       pv: 3000,
//       amt: 2100,
//     },
//   ];

//   const [exposureData, setExposureData] = useState([]);
//   const [forecastDate, setForecastDate] = useState('');
//   const [selectedCurrency, setSelectedCurrency] = useState('');
//   const [sensitivity, setSensitivity] = useState(1); // default value for sensitivity
//   const [spotRate, setSpotRate] = useState('');
//   const [volatility, setVolatility] = useState('');
//   const [forecastExposure, setForecastExposure] = useState(null);
//   const [operationsCost, setOperationsCost] = useState(''); // default value for operations cost
//   const [operationalRisk, setOperationalRisk] = useState(''); // default value for operational risk
//   const [selectedDataType, setSelectedDataType] = useState('Min Hedge');
//   const [dropdownTitle, setDropdownTitle] = useState('Min Hedge');
//   const [probability, setProbability] = useState(null);
//   const initialState = Array.from({ length: 12 }, () => ({
//     spotRate: '',
//     volatility: ''
//   }));
//   const [value, setValue] = useState(0);
//   const [state, setState] = useState(initialState);
//   const [optimalHedge, setOptimalHedge] = useState(0);
//   const handleSelect = (e) => {
//     setSelectedDataType(e);
//     switch (e) {
//       case 'optimalHedge':
//         setDropdownTitle('Optimal Hedge');
//         break;
//       case 'minHedge':
//         setDropdownTitle('Min Hedge');
//         break;
//       case 'hedgeRatio':
//         setDropdownTitle('Hedge Ratio');
//         break;
//       default:
//         setDropdownTitle('Select Data Type');
//     }
//   };

//   const k = spotRate * sensitivity * volatility
//   // const HedgeAmount = forecastExposure * (value / 100);
//   // const unhedge = forecastExposure - HedgeAmount
//   // Convert probability to decimal
//   const p = probability / 100;
//   const b = operationalRisk / 100;
//   const PE = forecastExposure * (probability / 100)
//   const hopt = (PE * k - operationsCost) / (k + spotRate * b)

//   console.log('spotRate:', spotRate);
//   console.log('b:', b);
//   console.log('operationsCost:', operationsCost);
//   console.log('hopt:', hopt);

//   // api for first card 
//   useEffect(() => {
//     if (selectedCurrency && forecastDate) {
//       getExposureByCurrency();
//     }
//   }, [selectedCurrency, forecastDate]);

//   //   const getExposureByCurrency = () => {
//   //     const config = {
//   //       headers: {
//   //         "Access-Control-Allow-Origin": "*",
//   //         "Content-type": "application/json",
//   //       },
//   //     };
//   //     let url = `${process.env.REACT_APP_BASEURL}exposure_for_currency_forecast/?currency=${selectedCurrency}&filter_by=current&forecast_date=${forecastDate}`;
//   //     axios
//   //       .get(url, config)
//   //       .then((res) => {
//   //         console.log(res.data.results, "data");

//   //         setExposureData(res.data.results);

//   //       })
//   //       .catch((err) => {
//   //         console.log(err, "err dashboard");
//   //       });
//   //   };
//   // ;

//   const getExposureByCurrency = () => {
//     const config = {
//       headers: {
//         "Access-Control-Allow-Origin": "*",
//         "Content-type": "application/json",
//       },
//     };
//     let url = `${process.env.REACT_APP_BASEURL}exposure_for_currency_forecast/?currency=${selectedCurrency}&filter_by=current&forecast_date=${forecastDate}`;
//     axios
//       .get(url, config)
//       .then((res) => {
//         console.log(res.data.results, "data");

//         // Update exposure data
//         setExposureData(res.data.results);
//         setForecastExposure(res.data.results[0]?.amount);
//         setSpotRate(res.data.results[0]?.spot_rate);
//         setVolatility(res.data.results[0]?.vol_data);
//         // Update spotRate and volatility
//         // if (res.data.results && res.data.results.length > 0) {
//         //   const firstItem = res.data.results[0]; // Assuming the first item contains the required data
//         //   setSpotRate(firstItem.spot_rate);
//         //   setVolatility(firstItem.vol_data);
//         // }
//       })
//       .catch((err) => {
//         console.log(err, "err dashboard");
//       });
//   };
//   // calculate Minimum Hedge 
//   const [minHedgeValues, setMinHedgeValues] = useState({});
//   const [hedgeRatios, setHedgeRatios] = useState({});

//   const calculateMinimumHedgeAmountForAllMonths = () => {
//     if (exposureData.length === 0) {
//       console.log("Exposure data is empty.");
//       return;
//     }

//     //   const minHedgeValuesObj = {};
//     //   exposureData.forEach((item, index) => {
//     //     const k = item.spot_rate * sensitivity * item.vol_data;
//     //     const b = parseFloat(operationalRisk) / 100;
//     //     const minHedgeValue = operationsCost / (k - item.spot_rate * b);
//     //     minHedgeValuesObj[item.month] = minHedgeValue.toFixed(2);
//     //   });
//     //   setMinHedgeValues(minHedgeValuesObj);
//     // };

//     // useEffect(() => {
//     //   calculateMinimumHedgeAmountForAllMonths();
//     // }, [exposureData, sensitivity, operationsCost, operationalRisk]);

//     const minHedgeValuesObj = {};
//     const hedgeRatiosObj = {};
//     exposureData.forEach((item) => {
//       const k = item.spot_rate * sensitivity * item.vol_data;
//       const p = probability / 100
//       const b = operationalRisk / 100;
//       const PE = forecastExposure * p;
//       const minHedgeValue = operationsCost / (k - item.spot_rate * b);
//       const hopt = (PE * k - operationsCost) / (k + item.spot_rate * b);
//       const hedgeRatio = hopt / item.amount;

//       minHedgeValuesObj[item.month] = minHedgeValue.toFixed(2);
//       hedgeRatiosObj[item.month] = hedgeRatio.toFixed(2);
//     });
//     setMinHedgeValues(minHedgeValuesObj);
//     setHedgeRatios(hedgeRatiosObj);
//   };

//   useEffect(() => {
//     calculateMinimumHedgeAmountForAllMonths();
//   }, [exposureData, sensitivity, operationsCost, operationalRisk]);

//   return (
//     <>
//       <Header />
//       <Container fluid className="pt-3" style={{ fontFamily: "sans-serif" }}>
//         {/* <Card className="border-1 border border-light-subtle shadow p-2 mb-4"> */}
//         <Row>
//           <Col sm={12} md={12} lg={12} className="mt-3">
//             <div className="row d-flex justify-content-evenly align-items-center p-3">
//               <span className="col-lg-2 col-md-6 col-sm-12 d-flex align-items-center">
//                 Risk(n):
//                 <input type="range" min="0" max="2" value={sensitivity} onChange={(e) => setSensitivity(e.target.value)} step="1" className='slider' />
//                 {/* <p>{sensitivity}</p> */}
//               </span>
//               <span className="col-lg-2 col-md-6 col-sm-12 d-flex align-items-center">
//                 Operating Cost(a):
//                 <Form.Group controlId="exampleForm.ControlInput1">
//                   <Form.Control size="sm" type="text" placeholder="Cost" className="custom_placeholder ms-3" value={operationsCost} onChange={(e) => setOperationsCost(e.target.value)} />
//                 </Form.Group>
//               </span>
//               <span className="col-lg-2 col-md-6 col-sm-12 d-flex align-items-center">
//                 Operational Risk(b):
//                 <Form.Group controlId="exampleForm.ControlInput1">
//                   <Form.Control size="sm" type="text" placeholder="Cost" className="custom_placeholder ms-3"
//                     // value={operationalRisk} 
//                     // onChange={(e) => setOperationalRisk(e.target.value)} 
//                     value={operationalRisk !== null ? `${operationalRisk}%` : ''}
//                     onChange={(e) => {
//                       const inputValue = e.target.value.replace('%', ''); // Remove '%' sign
//                       setOperationalRisk(inputValue);
//                     }} />
//                 </Form.Group>
//               </span>
//               <span className="col-lg-2 col-md-6 col-sm-12 d-flex align-items-center">
//                 Forecast Date:
//                 <Form.Group controlId="exampleForm.ControlInput1">
//                   <Form.Control size="sm" type="date" placeholder="Cost" className="custom_placeholder ms-3" onChange={(e) => setForecastDate(e.target.value)} />
//                 </Form.Group>
//               </span>
//               <span className="col-lg-2 col-md-6 col-sm-12 d-flex align-items-center">
//                 Select Currency:
//                 <Form.Group controlId="currency">
//                   <Form.Control as="select" size="sm" className="custom_placeholder ms-3" value={selectedCurrency} onChange={(e) => setSelectedCurrency(e.target.value)}>
//                     <option value="select">Select</option>
//                     <option value="GBP">GBP</option>
//                     <option value="MXP">MXP</option>
//                     <option value="EUR">EUR</option>
//                     <option value="CAD">CAD</option>
//                     <option value="HKD">HKD</option>
//                     {/* Add more currency options as needed */}
//                   </Form.Control>
//                 </Form.Group>
//               </span>
//             </div>
//           </Col>
//         </Row>

//         <Form>
//           <Row>
//             <Col sm={12} md={12} lg={4} className="mt-3">
//               <Card className="border-1 border border-light-subtle shadow ">
//                 <Card.Body className="text-center mt-2">
//                   <Table responsive >
//                     <thead>
//                       <tr>
//                         <th className="text-primary" style={{ fontSize: "15px" }}>Exposure Month</th>
//                         <th className="text-primary" style={{ fontSize: "15px" }}>Currency</th>
//                         <th className="text-primary" style={{ fontSize: "15px" }}>Forecast Exposure</th>
//                         <th className="text-primary" style={{ fontSize: "15px" }}>Probability</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {
//                         exposureData && exposureData.map((item, index) => (
//                           <tr key={index}>
//                             <td style={{ padding: '12px 0' }}>{item.month}</td>
//                             <td style={{ padding: '12px 0' }}>{item.currency}</td>
//                             <td style={{ padding: '12px 0' }}>{item.amount.toLocaleString('en-IN', { maximumFractionDigits: 1 })}</td>
//                             <td style={{ padding: '12px 0' }}>{(item.probability * 100).toFixed(2)}%</td>

//                           </tr>
//                         ))
//                       }
//                     </tbody>
//                   </Table>
//                 </Card.Body>
//                 <span className="d-flex justify-content-center align-items-center mb-2" style={{ padding: '12px 0' }}>
//                   <img src={logo} alt="Logo" className="rounded-circle img-fluid" style={{ cursor: "pointer", height: "35px" }} />
//                   <span className="small ms-2 text-primary" style={{ fontSize: "16px", fontWeight: "600" }}>Exposure Insight</span>
//                 </span>
//               </Card>
//             </Col>

//             <Col sm={12} md={12} lg={2} className="mt-3">
//               <Card className="border-1 border border-light-subtle shadow h-100">
//                 <Card.Body className="text-center mt-2">
//                   <Table responsive>
//                     <thead>
//                       <tr>
//                         <th className="text-primary" style={{ fontSize: "15px" }}>Spot Rate</th>
//                         <th className="text-primary" style={{ fontSize: "15px" }}>Volatility</th>
//                       </tr>
//                     </thead>

//                     <tbody>
//                       {
//                         exposureData && exposureData.map((item, index) => (
//                           <tr key={index}>
//                             <td style={{ padding: '12px 0' }}>{item.spot_rate}</td>
//                             <td style={{ padding: '12px 0' }}>{item.vol_data}</td>

//                             {/* <td>{calculateOptimalAmount(state[index]?.spotRate, state[index]?.volatility, operationalRisk, operationsCost, item.amount, item.probability * 100)}</td> */}
//                           </tr>
//                         ))
//                       }
//                     </tbody>
//                   </Table>
//                 </Card.Body>
//                 <span className="d-flex justify-content-center align-items-center mb-2" style={{ padding: '12px 0' }}>
//                   <img src={logo} alt="Logo" className="rounded-circle img-fluid" style={{ cursor: "pointer", height: "35px" }} />
//                   <span className="small ms-2 text-primary" style={{ fontSize: "16px", fontWeight: "600" }}>Market Data</span>
//                 </span>
//               </Card>
//             </Col>
//             <Col sm={12} md={12} lg={4} className="mt-3">
//               <Card className="border-1 border border-light-subtle shadow h-100">
//                 <Card.Body className="text-center mt-2">
//                   <Table responsive>
//                     <thead>
//                       <tr>
//                         {/* <th className="text-primary" style={{ fontSize: "15px" }}>Spot Rate</th>
//                         <th className="text-primary" style={{ fontSize: "15px" }}>Volatility</th> */}
//                         <th className="text-primary" style={{ fontSize: "15px" }}>Min Hedge</th>
//                         <th className="text-primary" style={{ fontSize: "15px" }}>Optimal Hedge</th>
//                         <th className="text-primary" style={{ fontSize: "15px" }}>Hedge Ratio</th>
//                         <th className="text-primary" style={{ fontSize: "15px" }}>Current Ratio</th>

//                       </tr>
//                     </thead>
//                     <tbody>
//                       {Object.keys(minHedgeValues).map((month, index) => (
//                         <tr key={index}>

//                           <td style={{ padding: '12px 0' }}>{minHedgeValues[month]}</td>
//                           {/* <td style={{ padding: '12px 0' }}>{(exposureData[index]?.amount * sensitivity * exposureData[index]?.vol_data - operationsCost) / (exposureData[index]?.amount * sensitivity * exposureData[index]?.vol_data + exposureData[index]?.spot_rate * (operationalRisk / 100))}</td> */}
//                           <td style={{ padding: '12px 0' }}>{hedgeRatios[month]}</td>
//                         </tr>
//                       ))}
//                     </tbody>

//                   </Table>
//                 </Card.Body>
//                 <span className="d-flex justify-content-center align-items-center mb-2" style={{ padding: '12px 0' }}>
//                   <img src={logo} alt="Logo" className="rounded-circle img-fluid" style={{ cursor: "pointer", height: "35px" }} />
//                   <span className="small ms-2 text-primary" style={{ fontSize: "16px", fontWeight: "600" }}>Risk Balancer</span>
//                 </span>
//               </Card>
//             </Col>

//             <Col sm={12} md={12} lg={2} className="mt-3">
//               {/* Button code Paste here */}


//             </Col>
//           </Row>
//         </Form>
//         <Row className="mt-4 mb-3">
//           {/* <Col>
//               <ResponsiveContainer width="100%" height={300}>
//                 <AreaChart
//                   width={500}
//                   height={200}
//                   data={data}
//                   syncId="anyId"
//                   margin={{
//                     top: 10,
//                     right: 30,
//                     left: 0,
//                     bottom: 0,
//                   }}
//                 >
//                   <CartesianGrid strokeDasharray="3 3" />
//                   <XAxis dataKey="name" />
//                   <YAxis />
//                   <Tooltip />
//                   <Area
//                     type="monotone"
//                     dataKey="pv"
//                     stroke="#00d5ae"
//                     fill="#1aa4c8"
//                   />
//                 </AreaChart>
//               </ResponsiveContainer>
//             </Col> */}
//           <Col sm={12} className="mt-3">
//             <Card className="border-1 border border-light-subtle shadow">
//               <Card.Body className="text-center mt-2">
//                 <DropdownButton
//                   id="dropdown-basic-button"
//                   title="Select Data Type"
//                   onSelect={handleSelect}
//                   className="mb-3"
//                 >
//                   <Dropdown.Item eventKey="optimalHedge">Optimal Hedge</Dropdown.Item>
//                   <Dropdown.Item eventKey="hedgeRatio">Hedge Ratio</Dropdown.Item>
//                   {/* <Dropdown.Item eventKey="minHedge">Min Hedge</Dropdown.Item> */}
//                 </DropdownButton>

//                 <ResponsiveContainer width="100%" height={500}>
//                   <AreaChart
//                     width={500}
//                     height={200}

//                     syncId="anyId"
//                     margin={{
//                       top: 10,
//                       right: 30,
//                       left: 0,
//                       bottom: 0,
//                     }}
//                   >
//                     <CartesianGrid strokeDasharray="3 3" />
//                     <XAxis dataKey="name" />
//                     <YAxis />
//                     <Tooltip />
//                     <Area
//                       type="monotone"
//                       dataKey={selectedDataType}
//                       stroke="#00d5ae"
//                       fill="#1aa4c8"
//                     />
//                   </AreaChart>
//                 </ResponsiveContainer>
//               </Card.Body>
//             </Card>
//           </Col>
//         </Row>

//         {/* </Card> */}
//       </Container>
//     </>
//   );
// };

// export default Analytics;





import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import { Form, Row, Col, Card, Table, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import Container from "react-bootstrap/Container";
import exposure from '../../Assets/exposure.png';
import risk from '../../Assets/risk.png';
import market from '../../Assets/market.png';
import axios from "axios";
import {
  LineChart,
  Line,
  Legend,
  Brush,
  AreaChart,
  Area,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import "./Analytics.css"
const CustomYAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={0} fontSize={15} textAnchor="end" fill="#666">
        {payload.value / 1000000}M
      </text>
    </g>
  );
};


const Analytics = ({ }) => {
  const [exposureData, setExposureData] = useState([]);
  const [forecastDate, setForecastDate] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [sensitivity, setSensitivity] = useState(1); // default value for sensitivity
  const [spotRate, setSpotRate] = useState('');
  const [volatility, setVolatility] = useState('');
  const [forecastExposure, setForecastExposure] = useState(null);
  const [operationsCost, setOperationsCost] = useState(''); // default value for operations cost
  const [operationalRisk, setOperationalRisk] = useState(''); // default value for operational risk
  const [probability, setProbability] = useState(null);
  const initialState = Array.from({ length: 12 }, () => ({
    spotRate: '',
    volatility: ''
  }));

  // ///////////// API //////////////////////
  useEffect(() => {
    if (selectedCurrency && forecastDate) {
      getExposureByCurrency();
    }
  }, [selectedCurrency, forecastDate]);

  const getExposureByCurrency = () => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      },
    };
    let url = `${process.env.REACT_APP_BASEURL}exposure_for_currency_forecast/?currency=${selectedCurrency}&filter_by=current&forecast_date=${forecastDate}`;
    axios
      .get(url, config)
      .then((res) => {
        console.log(res.data.results, "data");

        // Update exposure data
        setExposureData(res.data.results);
        setForecastExposure(res.data.results[0]?.amount);
        setSpotRate(res.data.results[0]?.spot_rate);
        setVolatility(res.data.results[0]?.vol_data);
      })
      .catch((err) => {
        console.log(err, "err dashboard");
      });
  };
  ///////////////////////CALCULATE MIN HEDGE///////////////////////////////
  const [minHedgeValues, setMinHedgeValues] = useState({});
  const [hedgeRatios, setHedgeRatios] = useState({});

  const calculateMinimumHedgeAmountForAllMonths = () => {
    if (exposureData.length === 0) {
      console.log("Exposure data is empty.");
      return;
    }
    const minHedgeValuesObj = {};
    const hedgeRatiosObj = {};

    exposureData.forEach((item) => {
      const k = item.spot_rate * sensitivity * item.vol_data;
      const p = probability / 100;
      const b = operationalRisk / 100;
      const PE = item.amount * p;
      const minHedgeValue = operationsCost / (k - item.spot_rate * b);
      const hopt = (PE * k - operationsCost) / (k + item.spot_rate * b);
      const hedgeRatio = hopt / item.amount;

      minHedgeValuesObj[item.month] = minHedgeValue.toFixed(2);
      hedgeRatiosObj[item.month] = (hedgeRatio * 100).toFixed(2); // Convert to percentage
    });

    setMinHedgeValues(minHedgeValuesObj);
    setHedgeRatios(hedgeRatiosObj);
  };

  useEffect(() => {
    calculateMinimumHedgeAmountForAllMonths();
  }, [exposureData, sensitivity, operationsCost, operationalRisk, probability]);


  ////////////////////////////CALCULATE HEDGE AMOUNT///////////////////
  const calculateOptimalAmount = (spotRate, volatility, operationalRisk, operationsCost, forecastAmount, probability) => {
    if (
      forecastAmount !== null &&
      spotRate > 0 &&
      volatility > 0 &&
      probability !== null &&
      sensitivity !== null &&
      operationsCost !== null &&
      operationalRisk !== null
    ) {
      const k = spotRate * sensitivity * volatility;
      const p = probability / 100;
      const b = operationalRisk / 100;
      const PE = forecastAmount * p;
      const hopt = (PE * k - operationsCost) / (k + spotRate * b);
      const optimalHedge = hopt / forecastAmount;
      return isNaN(optimalHedge) ? 0 : (optimalHedge * 100).toFixed(2); // return as percentage
    }
    return 0;
  };


  ////////////////////////CURRENT HEDGE START//////////////////////////
  const [currentHedgeValues, setCurrentHedgeValues] = useState({});

  const calculateCurrentHedgeAmountForAllMonths = () => {
    if (exposureData.length === 0) {
      console.log("Exposure data is empty.");
      return;
    }
    const currentHedgeValuesObj = {};

    exposureData.forEach((item) => {
      const currentHedgeValue = (item.amount * item.current_ratio) / 100;
      currentHedgeValuesObj[item.month] = currentHedgeValue.toFixed(2);
    });

    setCurrentHedgeValues(currentHedgeValuesObj);
  };

  useEffect(() => {
    calculateCurrentHedgeAmountForAllMonths();
  }, [exposureData]);

  ////////////////////////CURRENT HEDGE END//////////////////////////

  ////////////////////////CURRENT UNHEDGE START////////////////////////////
  const [currentUnhedgeValues, setCurrentUnhedgeValues] = useState({});

  const calculateCurrentUnhedgeAmountForAllMonths = () => {
    if (exposureData.length === 0) {
      console.log("Exposure data is empty.");
      return;
    }
    const currentUnhedgeValuesObj = {};

    exposureData.forEach((item) => {
      const currentHedgeValue = (item.amount * item.current_ratio) / 100;
      const currentUnhedgeValue = item.amount - currentHedgeValue;
      currentUnhedgeValuesObj[item.month] = currentUnhedgeValue.toFixed(2);
    });

    setCurrentUnhedgeValues(currentUnhedgeValuesObj);
  };

  useEffect(() => {
    calculateCurrentUnhedgeAmountForAllMonths();
  }, [exposureData]);


  ////////////////////////CURRENT UNHEDGE END////////////////////////////

  ////////////////////////Graph data////////////////////////
  const [graphData, setGraphData] = useState([]);

  const updateGraphData = (data) => {
    const updatedData = data.map((item, index) => {
      const hedgeRatio = calculateOptimalAmount(
        item.spot_rate,
        item.vol_data,
        operationalRisk,
        operationsCost,
        item.amount,
        item.probability * 100
      );

      const optimalHedge = (item.amount * hedgeRatio) / 100;
      const currentHedge = (item.amount * item.current_ratio) / 100;
      const currentUnhedge = item.amount - currentHedge;
      return {
        name: item.month,
        Forecast: item.amount ,
        OptimalHedge: optimalHedge.toFixed(1),
        MinimumHedged: minHedgeValues[item.month] ? parseFloat(minHedgeValues[item.month]) : 0,
        CurrentHedge: currentHedge.toFixed(1),
        CurrentUnhedge: currentUnhedge.toFixed(1),
      };
    });
    setGraphData(updatedData);
  };

  useEffect(() => {
    if (exposureData.length > 0 && Object.keys(minHedgeValues).length > 0) {
      updateGraphData(exposureData);
    }
  }, [exposureData, minHedgeValues]);

  useEffect(() => {
    console.log("graphData", graphData);
  }, [graphData]);

  const hasData = graphData && graphData.length > 0;

  ////////////////////////////////JSX CODE START HERE////////////////////////////

  
  return (
    <>
      <Header />

      <Container fluid className="pt-3" >
      <h6 style={{ fontWeight: 600, fontSize: "24px" }}>Dynamic Hedging</h6>
        <Card className="border-1 border border-light-subtle shadow " style={{ borderRadius: '10px', borderColor: '#C3D3E2', backgroundColor: "#FFFFFF", }}> {/* Use 'bg-secondary' for grey background */}
          <Row>
            <Col sm={12} md={12} lg={12} className="mt-3">
              <div className="row d-flex justify-content-evenly  p-3">
                <span className="col-lg-2 col-md-6 col-sm-12 d-flex align-items-center" style={{ color: '#737791' }}>
                  Risk(n):&nbsp;
                  <input type="range" min="0" max="2" value={sensitivity} onChange={(e) => setSensitivity(e.target.value)} step="1" className='slider' />
                  <div style={{ marginLeft: '10px' }}>{sensitivity}</div>
                </span>
                <span className="col-lg-2 col-md-6 col-sm-12 d-flex align-items-center" style={{ color: '#737791', fontSize: '15px' }}>
                  Operating Cost(a):
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control style={{ borderRadius: '10px' }} size="sm" type="text" placeholder="Cost" className="custom_placeholder ms-3" value={operationsCost} onChange={(e) => setOperationsCost(e.target.value)} />
                  </Form.Group>
                </span>
                <span className="col-lg-2 col-md-6 col-sm-12 d-flex align-items-center" style={{ color: '#737791' }}>
                  Operational Risk(b):
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control style={{ borderRadius: '10px' }} size="sm" type="text" placeholder="Risk" className="custom_placeholder ms-3"
                      value={operationalRisk !== null ? `${operationalRisk}%` : ''}
                      onChange={(e) => {
                        const inputValue = e.target.value.replace('%', ''); // Remove '%' sign
                        setOperationalRisk(inputValue);
                      }} />
                  </Form.Group>
                </span>
                <span className="col-lg-2 col-md-6 col-sm-12 d-flex align-items-center" style={{ color: '#737791' }}>
                  Forecast Date:
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control style={{ borderRadius: '10px' }} size="sm" type="date" placeholder="Date" className="custom_placeholder ms-3" onChange={(e) => setForecastDate(e.target.value)} />
                  </Form.Group>
                </span>
                <span className="col-lg-2 col-md-6 col-sm-12 d-flex align-items-center" style={{ color: '#737791' }}>
                  Select Currency:
                  <Form.Group controlId="currency">
                    <Form.Control style={{ borderRadius: '10px' }} s as="select" size="sm" className="custom_placeholder ms-3" value={selectedCurrency} onChange={(e) => setSelectedCurrency(e.target.value)}>
                      <option value="select">Select</option>
                      <option value="GBP">GBP</option>
                      <option value="MXP">MXP</option>
                      <option value="EUR">EUR</option>
                      <option value="CAD">CAD</option>
                      <option value="HKD">HKD</option>
                      {/* Add more currency options as needed */}
                    </Form.Control>
                  </Form.Group>
                </span>
              </div>
            </Col>
          </Row>
        </Card>
        <Form>
          <Row>
            {/* FIRST CARD HERE */}
            <Col sm={12} md={12} lg={4} className="mt-3">
              <Card className="border-1 border border-light-subtle shadow h-80" style={{ borderRadius: '10px' }}>
                <Card.Body className="text-center mt-2">
                  <Table responsive striped>
                    <thead>
                      <tr>
                        <th style={{ color: '#02A6D9', fontSize: '16px', fontWeight: 'bold' }}>Exposure Month</th>
                        <th style={{ color: '#02A6D9', fontSize: '16px', fontWeight: 'bold' }}>Currency</th>
                        <th style={{ color: '#02A6D9', fontSize: '16px', fontWeight: 'bold' }}>Forecast Exposure</th>
                        <th style={{ color: '#02A6D9', fontSize: '16px', fontWeight: 'bold' }}>Probability</th>
                      </tr>
                    </thead>
                    <tbody>
                      {exposureData && exposureData.map((item, index) => (
                        <tr key={index}>
                          <td style={{ padding: '12px 0' }}>{item.month}</td>
                          <td style={{ padding: '12px 0' }}>{item.currency}</td>
                          <td style={{ padding: '12px 0' }}>{item.amount.toLocaleString('en-IN', { maximumFractionDigits: 1 })}</td>
                          <td style={{ padding: '12px 0' }}>{(item.probability * 100).toFixed(2)}%</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
                <div className="d-flex justify-content-center align-items-center shadow " style={{ padding: '12px 0', backgroundColor: 'rgba(243,218,206,1)' }}>
                  <img src={risk} alt="Logo" style={{ cursor: "pointer", }} />
                  <span className="small ms-2 " style={{ fontSize: "16px", fontWeight: "600", color: '#FF947A' }}>Exposure Insight</span>
                </div>
              </Card>

            </Col>
            {/* SECOND CARD HERE */}
            <Col sm={12} md={12} lg={2} className="mt-3">
              <Card className="border-1 border border-light-subtle shadow h-80" style={{ borderRadius: '10px' }}>
                <Card.Body className="text-center mt-2">
                  <Table responsive striped>
                    <thead>
                      <tr>
                        <th style={{ color: '#02A6D9', fontSize: '16px', fontWeight: 'bold' }}>Spot Rate</th>
                        <th style={{ color: '#02A6D9', fontSize: '16px', fontWeight: 'bold' }}>Volatility</th>
                      </tr>
                    </thead>
                    <tbody>
                      {exposureData && exposureData.map((item, index) => (
                        <tr key={index}>
                          <td style={{ padding: '12px 0' }}>{(item.spot_rate.toFixed(4))}</td>
                          <td style={{ padding: '12px 0' }}>{(item.vol_data).toFixed(4)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
                <div className="d-flex justify-content-center align-items-center shadow " style={{ padding: '12px 0', backgroundColor: 'rgba(207,228,249,1)' }}>
                  <img src={market} alt="Logo" className=" img-fluid" style={{ cursor: "pointer", }} />
                  <span className="small ms-2 " style={{ fontSize: "16px", fontWeight: "600", color: '#6D9EE9' }}>Market Data</span>
                </div>
              </Card>
            </Col>
            {/* THIRD CARD HERE */}
            <Col sm={12} md={12} lg={6} className="mt-3">
              <Card className="border-1 border border-light-subtle shadow h-80" style={{ borderRadius: '10px' }}>
                <Card.Body className="text-center mt-2">
                  <Table responsive striped>
                    <thead>
                      <tr>
                        <th style={{ color: '#02A6D9', fontSize: '16px', fontWeight: 'bold' }}>Min Hedge</th>
                        <th style={{ color: '#02A6D9', fontSize: '16px', fontWeight: 'bold' }}>Optimal Hedge</th>
                        <th style={{ color: '#02A6D9', fontSize: '16px', fontWeight: 'bold' }}>Hedge Ratio</th>
                        <th style={{ color: '#02A6D9', fontSize: '16px', fontWeight: 'bold' }}>Current Ratio</th>
                        <th style={{ fontSize: '15px', color: '#02A6D9', fontSize: '16px', fontWeight: 'bold' }}>Hedge</th>
                      </tr>
                    </thead>
                    {/* <tbody>
                      {exposureData && exposureData.map((item, index) => (
                        <tr key={index}>
                          <td style={{ padding: '12px 0' }}>{minHedgeValues[item.month]}</td>
                          <td style={{ padding: '12px 0' }}>{"OPTIMAL"}</td>
                          <td style={{ padding: '12px 0' }}>
                            {calculateOptimalAmount(
                              item.spot_rate,
                              item.vol_data,
                              operationalRisk,
                              operationsCost,
                              item.amount,
                              item.probability * 100
                            )} %
                          </td>
                          <td style={{ padding: '12px 0' }}>{item.current_ratio}%</td>
                        </tr>
                      ))}
                    </tbody> */}
                    <tbody className="">
                      {exposureData && exposureData.map((item, index) => {
                        const hedgeRatio = calculateOptimalAmount(
                          item.spot_rate,
                          item.vol_data,
                          operationalRisk,
                          operationsCost,
                          item.amount,
                          item.probability * 100
                        );

                        // Calculate the Optimal Hedge
                        const optimalHedge = (item.amount * hedgeRatio) / 100;

                        // Calculate the Current Unhedge value in millions
                        const currentUnhedge = (((hedgeRatio - item.current_ratio) * item.amount / 100) / 1000000).toFixed(1);

                        // Determine whether to show "Buy", "Sell" or "Hold" button based on comparison
                        // let actionButton;
                        // if (hedgeRatio > item.current_ratio) {
                        //   actionButton = "Buy";
                        // } else if (hedgeRatio < item.current_ratio) {
                        //   actionButton = "Sell";
                        // } else {
                        //   actionButton = "Hold";
                        // }

                        let numericHedgeRatio = Number(hedgeRatio);
                        let numericCurrentRatio = Number(item.current_ratio);

                        let actionButton;
                        console.log(`Comparing Hedge Ratio: ${numericHedgeRatio} with Current Ratio: ${numericCurrentRatio}`);
                        if (numericHedgeRatio > numericCurrentRatio) {
                          actionButton = "Buy";
                        } else if (numericHedgeRatio < numericCurrentRatio) {
                          actionButton = "Sell";
                        } else {
                          actionButton = "Hold";
                        }
                        console.log("Determined Action Button:", actionButton);

                        console.log("Hedge Ratio:", hedgeRatio);
                        console.log("Current Ratio:", item.current_ratio);
                        console.log("Action Button:", actionButton);

                        return (
                          <tr key={index}>
                            <td style={{ padding: '8.5px 0',textAlign:'center' }}>{minHedgeValues[item.month]}</td>
                            <td style={{ padding: '8.5px 0' }}>{optimalHedge.toLocaleString('en-IN', { maximumFractionDigits: 1 })}</td>
                            <td style={{ padding: '8.5px 0' }}>{hedgeRatio}% </td>
                            <td style={{ padding: '8.5px 0' }}>{item.current_ratio}%</td>
                            <td style={{ padding: '8.5px 0' }}>
                              <Button
                                size="sm"
                                style={{
                                  fontWeight:actionButton === "Buy" ? "bold" : actionButton === "Sell" ? "400" : "400",
                                  backgroundColor: actionButton === "Buy" ? "#49b7b7" : actionButton === "Sell" ? "#ff4d4d" : "#ffcc00",
                                  borderColor: actionButton === "Buy" ? "#49b7b7" : actionButton === "Sell" ? "#ff4d4d" : "#ffcc00",
                                }}
                                className="w-75"
                              >
                                {actionButton}&nbsp;&nbsp;
                                <span style={{ fontWeight: 'bold' }}>{currentUnhedge}K</span> 
                              </Button>
                            </td>
                            {/* <td>{currentUnhedge}</td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
                 <div className="d-flex justify-content-center align-items-center shadow " style={{ padding: '12px 0', backgroundColor: 'rgba(181,217,211,0.8)' }}>
                  <img src={exposure} alt="Logo" style={{ cursor: "pointer" }} />
                  <span className="small ms-2 " style={{ fontSize: "16px", fontWeight: "600", color: '#55A395' }}>Risk Balancer</span>
                </div>
              </Card>
            </Col>
            <Col sm={12} md={12} lg={2} className="mt-3">
              {/* Button code Paste here */}
            </Col>
          </Row>
        </Form>
        {/* GRAPH HERE */}
        <Row className="mt-4 mb-3">
          <Col>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={graphData}> 
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis tick={<CustomYAxisTick />} />
                <Tooltip />
                {hasData && <Legend verticalAlign="bottom" height={36} style />} {/* Conditionally render the legend */}
                <Bar dataKey="Forecast" fill="#00637c" minPointSize={5} />
                <Bar dataKey="OptimalHedge" fill="#e56717" minPointSize={5} />
                <Bar dataKey="MinimumHedged" fill="#008000" minPointSize={5} />
                <Bar dataKey="CurrentHedge" fill="#50b8e2" minPointSize={5} />
                <Bar dataKey="CurrentUnhedge" fill="purple" minPointSize={5} />
              </BarChart>
            </ResponsiveContainer>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Analytics