import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Calculator from "./Components/Screens/Calculator";
import Sidebar from "./Components/Sidebar/Sidebar";
import Dashboard from "./Components/Screens/Dashboard";
import Collection from "./Components/Screens/Collection";
import Analytics from "./Components/Screens/Analytics";
import Product from "./Components/Screens/Product";
import ProductList from "./Components/Screens/ProductList";
import Login from "./Components/Screens/Login";
import LandingPage from "./Components/LandingPage/LandingPage";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Sidebar><Dashboard /></Sidebar>} />
        <Route path="/collection" element={<Sidebar><Collection /></Sidebar>} />
        <Route path="/calculator" element={<Sidebar><Calculator /></Sidebar>} />
        <Route path="/analytics" element={<Sidebar><Analytics /></Sidebar>} />
        <Route path="/product" element={<Sidebar><Product /></Sidebar>} />
        <Route path="/productList" element={<Sidebar><ProductList /></Sidebar>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
