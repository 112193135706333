import React, { useEffect, useState } from 'react'
import Header from '../Header/Header';
import { Col, Row, Button, Container } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Chart from 'react-apexcharts';
import ReactApexChart from 'react-apexcharts';
import './Hedgeration.css'
import { BsGraphUpArrow } from "react-icons/bs";
const colors = ['#008FFB', '#008FFB', '#008FFB', '#008FFB', '#008FFB', '#008FFB', '#008FFB', '#008FFB'];


function Calculator() {
  const [value, setValue] = useState(0);
  const [showCards, setShowCards] = useState(true);
  const [forecastExposure, setForecastExposure] = useState(null);
  const [currency, setCurrency] = useState('GBP');
  const [spotRate, setSpotRate] = useState(null);
  const [volatility, setVolatility] = useState(null);
  const [probability, setProbability] = useState(null);
  const [sensitivity, setSensitivity] = useState(null);
  const [operationsCost, setOperationsCost] = useState(null);
  const [operationalRisk, setOperationalRisk] = useState(null);
  const [potentialFXLoss, setPotentialFXLoss] = useState(null)
  const [unwindCost, setUnwindCost] = useState(null);
  const [operationalCostRisk, setOperationalCostRisk] = useState(null)
  const [graphData, setGraphData] = useState([]);
  const [minimumHedgeAmount, setMinimumHedgeAmount] = useState(0);
  const [findOptimal, setFindOptimal] = useState(null);
  const [optimalPercentage, setOptimalPercentage] = useState(0);


  const toggleView = () => {
    setShowCards(!showCards);
  };

  const [series] = useState([{ data: [2100, 2200, 1000] }]);
  const [options] = useState({
    chart: {
      height: 350,
      type: 'bar',
      events: {
        click: (chart, w, e) => {
          // console.log(chart, w, e)
        },
      },
    },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: ['Potential FX Loss', 'Potential Unwind Cost', 'Operational Cost/Risk'],
      labels: {
        style: {
          // colors: colors,
          fontSize: '12px',
        },
      },
    },
  });

  // const series = [{
  //   name: 'Sales',
  //   data: [100, 65, 25,]
  // }];

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const k = spotRate * sensitivity * volatility
  // Convert probability to decimal
  const p = probability / 100;
  const b = operationalRisk / 100;
  const PE = forecastExposure * (probability / 100)
  const hopt = (PE * k - operationsCost) / (k + spotRate * b)

  console.log('hopt:', hopt);
  console.log('spotRate:', spotRate);
  console.log('b:', b);
  console.log('operationsCost:', operationsCost);


  useEffect(() => {
    if (
      forecastExposure !== null &&
      spotRate !== null &&
      volatility !== null &&
      probability !== null &&
      sensitivity !== null &&
      value !== null &&
      operationsCost !== null &&
      operationalRisk !== null
    ) {
      const p = probability / 100;
      const k = spotRate * sensitivity * volatility;
      const PE = forecastExposure * (probability / 100);
      const hopt = ((PE * k - operationsCost) / (k + spotRate * (operationalRisk / 100)));
      const operationalCostRisk = hopt * spotRate * (operationalRisk / 100) + parseFloat(operationsCost);
      setOperationalCostRisk(operationalCostRisk);

      const newPotentialFXLoss = (forecastExposure - (forecastExposure * value) / 100) * (spotRate * sensitivity * volatility * p);
      setPotentialFXLoss(newPotentialFXLoss);

      const newUnwindCost = (forecastExposure * (value / 100)) * (1 - p) * k;
      setUnwindCost(newUnwindCost);

      const newData = [
        potentialFXLoss !== null ? parseFloat(potentialFXLoss.toFixed(2)) : 0,
        unwindCost !== null ? parseFloat(unwindCost.toFixed(2)) : 0,
        operationalCostRisk !== null ? parseFloat(operationalCostRisk.toFixed(2)) : 0
      ];
      setGraphData([{ data: newData }]);
      // Update minimum hedge amount
      const newMinimumHedgeAmount = operationsCost / (k - spotRate * (operationalRisk / 100));
      setMinimumHedgeAmount(newMinimumHedgeAmount);
      console.log(newData, "graph")
    }
  }, [forecastExposure, spotRate, volatility, probability, sensitivity, value, operationsCost, operationalRisk, potentialFXLoss, unwindCost, operationalCostRisk]);


  // Find Optimal Percentage value
  const calculateOptimalAmount = () => {
    if (
      forecastExposure !== null &&
      spotRate !== null &&
      volatility !== null &&
      probability !== null &&
      sensitivity !== null &&
      operationsCost !== null &&
      operationalRisk !== null
    ) {
      const p = probability / 100;
      const k = spotRate * sensitivity * volatility;
      const PE = forecastExposure * (probability / 100);
      const hopt = ((PE * k - operationsCost) / (k + spotRate * (operationalRisk / 100))) / forecastExposure;
      const calculatedOptimalPercentage = hopt * 100;
      setValue(calculatedOptimalPercentage.toFixed(2)); // Set value directly
      setOptimalPercentage(calculatedOptimalPercentage.toFixed(2));

    }
  };

  const smoothTransitionToValue = (targetValue) => {
    const currentValue = value;
    const step = (targetValue - currentValue) / 100; // Change the step value as needed for a smoother transition
    let currentStep = 0;

    const interval = setInterval(() => {
      const newValue = currentValue + step * currentStep;
      setValue(newValue);

      currentStep++;
      if (currentStep > 100) {
        clearInterval(interval);
      }
    }, 20); // Change the interval time as needed for a smoother transition
  };

  const hedgeAmount = (forecastExposure * value) / 100;

  // Determine background color based on the conditions
  let buttonBackgroundColor;
  if (minimumHedgeAmount === hedgeAmount) {
    buttonBackgroundColor = '#9ccc65';
  } else if (minimumHedgeAmount > hedgeAmount) {
    buttonBackgroundColor = '#ef5350';
  } else {
    buttonBackgroundColor = '#9ccc65';
  }



  return (
    <div>
      <Header />
      <Container fluid className="pt-3 ps-5 pe-5" >
        <h6 style={{ fontWeight: 600, fontSize: "24px" }}>Risk Balancer Calculator</h6>
        <Form className='mt-3 '>
          <h5 className='col-12' style={{color:'#737791',fontWeight: 400, fontSize: "18px" }}>This Calculator is used to manually or automatically determine the optimal hedge ratio</h5>
          <Row className='col-12 mt-4 '>
            <Col sm={12} lg={3}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control size="lg" style={{ backgroundColor: '#f4f4ff',border:'none' }} type="number" placeholder="Forecast amount" value={forecastExposure} onChange={(e) => setForecastExposure(e.target.value)} ></Form.Control>
              </Form.Group>
            </Col>
            <Col sm={12} lg={3}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlSelect1">
                <Form.Select size="lg" value={currency} onChange={(e) => setCurrency(e.target.value)} style={{ backgroundColor: '#f4f4ff',border:'none' }}>
                  <option value="GBP">GBP</option>
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  {/* Add more options as needed */}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={12} lg={3}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control style={{ backgroundColor: '#f4f4ff',border:'none' }} size="lg" type="number" placeholder=" Spot rate" value={spotRate} onChange={(e) => setSpotRate(e.target.value)} />
              </Form.Group>
            </Col>
            <Col sm={12} lg={3}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control size="lg" style={{ backgroundColor: '#f4f4ff',border:'none' }} type="number" placeholder=" Volatility" value={volatility} onChange={(e) => setVolatility(e.target.value)} />
              </Form.Group>
            </Col>
            <Col sm={12} lg={3}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control style={{ backgroundColor: '#f4f4ff',border:'none' }} size="lg" type="number" placeholder=" Probability"
                  value={probability}
                  onChange={(e) => setProbability(e.target.value)} />
              </Form.Group>
            </Col>
            <Col sm={12} lg={3}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control style={{ backgroundColor: '#f4f4ff',border:'none' }} size="lg" type="number" placeholder=" Risk modal (n)" value={sensitivity} onChange={(e) => setSensitivity(e.target.value)} />
              </Form.Group>
            </Col>
            <Col sm={12} lg={3}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control style={{ backgroundColor: '#f4f4ff' ,border:'none'}} size="lg" type="number" placeholder=" Operation cost (a)" value={operationsCost} onChange={(e) => setOperationsCost(e.target.value)} />
              </Form.Group>
            </Col>
            <Col sm={12} lg={3}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control
                style={{ backgroundColor: '#f4f4ff' ,border:'none'}}
                  size="lg"
                  type="text"
                  placeholder="Enter Operation risk (b)"
                  value={operationalRisk !== null ? `${operationalRisk}%` : ''}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace('%', ''); // Remove '%' sign
                    setOperationalRisk(inputValue);
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12} lg={3}>
              {/* k= {JSON.stringify(k)}<br></br>
                unhedge = {JSON.stringify(forecastExposure - HedgeAmount)}<br></br>
                PE= {JSON.stringify(PE)}<br></br>
                Hopt= {JSON.stringify(hopt)} */}
            </Col>
          </Row>
        </Form>
        {/* Hedge Ratio section */}
        <div className='d-flex justify-content-center mt-5'>
          <p style={{ fontWeight: 'bold', fontSize: "25px", color: "#02A6D9" }}>
            Hedge Ratio
          </p>
        </div>
        <div className='d-flex justify-content-center' style={{ marginRight: '60px' }}>
          <Row className='col-12 d-flex justify-content-center '>
            <Col className='d-flex justify-content-center'>
            </Col>
            <Col lg={6} className=' mt-3'>
              <input type="range" min="0" max="100" value={value} onChange={handleChange} step="0.01" className='slider' />
              <div className='d-flex justify-content-center'>
                <h1>{value}%</h1>
              </div>
              <div className='d-flex justify-content-end'>
                <h5 style={{ color: 'grey' }}>Hedge amount</h5>
              </div>
              <div className='d-flex justify-content-end'>
                <h5 style={{ fontWeight: '600', fontSize: '25px', backgroundColor: '' }}>{hedgeAmount.toLocaleString('en-IN')}</h5>
              </div>
            </Col>
            <Col>
              <div className='d-flex justify-content-center mt-3'>
                <Button style={{ backgroundColor: '#0BD0D9', borderColor: '#0BD0D9', padding: '5px 40px' }} onClick={calculateOptimalAmount}>Find Optimal</Button>
              </div>
              <div className='d-flex justify-content-center mt-3'>
                <h6>Optimal Hedge Amount: {(forecastExposure * optimalPercentage / 100).toLocaleString('en-IN')}</h6>
              </div>

            </Col>
          </Row>
        </div>
        {/* Potential risks section */}
        <div>
          <div className='d-flex justify-content-center mt-3'>
            <p style={{ fontWeight: 'bold', fontSize: "25px", color: "#02A6D9" }}>
              Potential Risk
            </p>
          </div>
          <div className='d-flex justify-content-center '>
            <Button onClick={toggleView} style={{  padding: '5px 15px', backgroundColor: '#0BD0D9', borderColor: '#0BD0D9', color: 'white', cursor: 'pointer', }}>
            <BsGraphUpArrow /> &nbsp;{showCards ? 'Graph' : 'Graph'}
            </Button>
          </div>
        </div>
        {showCards ? (<>
          <div className='d-flex justify-content-center mt-2'   >
            <Row className='col-9 d-flex justify-content-center '>
              <Col lg={2} className=' mt-3'>
                <Card style={{ margin: '', boxShadow: '1px 1px 1px 0px #808080db', height:'13vh' }}>
                  <Card.Body className='text-center'>
                    <Card.Title style={{ fontSize: '20px', fontWeight: '400' }}>Potential FX Loss</Card.Title>
                    <Card.Text style={{ fontSize: '22px',marginTop:'10%'}}>
                      {potentialFXLoss !== null ? potentialFXLoss.toLocaleString('en-IN', { maximumFractionDigits: 2 }) : "0"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={2} className=' mt-3'>
                <Card style={{ margin: '', boxShadow: '1px 1px 1px 0px #808080db' ,height:'13vh'}}>
                  <Card.Body className='text-center'>
                    <Card.Title style={{ fontSize: '20px', fontWeight: '400' }}>Potential Unwind Cost</Card.Title>
                    <Card.Text style={{ fontSize: '22px' }}>
                      {unwindCost !== null ? unwindCost.toLocaleString('en-IN', { maximumFractionDigits: 2 }) : "0"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={2} className=' mt-3'>
                <Card style={{ margin: '', boxShadow: '1px 1px 1px 0px #808080db',height:'13vh' }}>
                  <Card.Body className='text-center'>
                    <Card.Title style={{ fontSize: '20px', fontWeight: '400' }}>Operational Cost/Risk</Card.Title>
                    <Card.Text style={{ fontSize: '22px' }}>
                      {operationalCostRisk !== null ? operationalCostRisk.toLocaleString('en-IN', { maximumFractionDigits: 2 }) : "0"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
          <Row className='col-12 d-flex justify-content-end ' style={{ marginTop: '-60px',marginLeft:'-50px' }}>
            <Col sm={12} lg={3}>
              <Card style={{ margin: '10px', borderColor: '#FFF' }}>
                <Card.Body className='text-center'>
                  <Card.Title style={{ fontSize: '20px', fontWeight: '400', color: 'grey' }}>Minimum Hedge Amount</Card.Title>
                  <Button style={{ padding: '5px 70px', fontSize: '20px', backgroundColor: buttonBackgroundColor, color: 'white', border: 'none', borderRadius: '10px', cursor: 'pointer', fontWeight: '600' }}>{minimumHedgeAmount.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
        ) : (
          <Row>
            <Col className='d-flex justify-content-center mt-4'>
              <ReactApexChart options={options} series={graphData} type="bar" height={350} width={800} />
            </Col>
          </Row>
        )}

        {/* {showCards ? (
            <Row className='col-12 p-5 '>
              <Col sm={12} lg={3}>
                <Card style={{ margin: '10px', boxShadow: '1px 2px 5px 0px #808080db' }}>
                  <Card.Body className='text-center'>
                    <Card.Title style={{ fontSize: '24px', fontWeight: '500' }}>Potential FX Loss</Card.Title>
                    <Card.Text style={{ fontSize: '22px' }}>

                      {potentialFXLoss !== null ? potentialFXLoss.toLocaleString('en-IN', { maximumFractionDigits: 2 }) : "0"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} lg={3}>
                <Card style={{ margin: '10px', boxShadow: '1px 2px 5px 0px #808080db' }}>
                  <Card.Body className='text-center'>
                    <Card.Title style={{ fontSize: '24px', fontWeight: '500' }}>Potential Unwind Cost</Card.Title>
                    <Card.Text style={{ fontSize: '22px' }}>
                      {unwindCost !== null ? unwindCost.toLocaleString('en-IN', { maximumFractionDigits: 2 }) : "0"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} lg={3}>
                <Card style={{ margin: '10px', boxShadow: '1px 2px 5px 0px #808080db' }}>
                  <Card.Body className='text-center'>
                    <Card.Title style={{ fontSize: '24px', fontWeight: '500' }}>Operational Cost/Risk</Card.Title>
                    <Card.Text style={{ fontSize: '22px' }}>
                      {operationalCostRisk !== null ? operationalCostRisk.toLocaleString('en-IN', { maximumFractionDigits: 2 }) : "0"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} lg={3}>
                <Card style={{ margin: '10px', borderColor: '#FFF' }}>
                  <Card.Body className='text-center'>
                    <Card.Title style={{ fontSize: '20px', fontWeight: '500', color: 'grey' }}>Minimum Hedge Amount</Card.Title>
                    <button style={{ padding: '5px 70px', fontSize: '20px', backgroundColor: buttonBackgroundColor, color: 'white', border: 'none', borderRadius: '50px', cursor: 'pointer', fontWeight: '600' }}>{minimumHedgeAmount.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className='d-flex justify-content-center mt-4'>
                <ReactApexChart options={options} series={graphData} type="bar" height={300} width={600} />

            
                <div>
                  <div id="chart">
                   
                  </div>
                  <div id="html-dist"></div>
                </div>
              </Col>
            </Row>
          )
          } */}

      </Container>
    </div>
  )
}

export default Calculator